import { dashboardService } from "../../services/dashboard.service";
import { DisplayAngle } from "../fixtureAngle";
import { CompetitionAlertData } from "../../utils/hooks/useDashboardCompetition";
import { FixtureData, File } from "../../utils/hooks/useDashboardFixture";

interface csvHeader {
    key: string;
    value: string;
}

const headers: csvHeader[] = [
    {
        key: "date",
        value: "Date"
    },
    {
        key: "time",
        value: "Time"
    },
    {
        key: "competition_name",
        value: "Competition Name"
    },
    {
        key: "home_team",
        value: "Home Team"
    },
    {
        key: "vs",
        value: "VS"
    },
    {
        key: "away_team",
        value: "Away Team"
    },
    {
        key: "referee",
        value: "Referee"
    },
    {
        key: "VIDEO_BROADCAST",
        value: "Broadcast"
    },
    {
        key: "VIDEO_TACTICAL",
        value: "Tactical"
    },
    {
        key: "VIDEO_EIGHTEEN_YARD_ONE",
        value: "18YD1"
    },
    {
        key: "VIDEO_EIGHTEEN_YARD_TWO",
        value: "18YD2"
    },
    {
        key: "VIDEO_HIGH_BEHIND_ONE",
        value: "HB1"
    },
    {
        key: "VIDEO_HIGH_BEHIND_TWO",
        value: "HB2"
    },
    {
        key: "VIDEO_PANORAMIC",
        value: "Panoramic"
    },
    {
        key: "VIDEO_BROADCAST_COMMS",
        value: "Broad Comms"
    },
    {
        key: "AUDIO_COMMS",
        value: "Audio Comms"
    },
    {
        key: "status",
        value: "Status"
    },
]

interface fixtureWithCompetition {
    competition: CompetitionAlertData
    fixture: FixtureData
}


export const DashboardExport = async (minDate?: Date, maxDate?: Date) => {
    // Get all competitions the user can access
    const competitions = await dashboardService.getCompetitionAlerts({
        ...(minDate && { min_date: minDate?.toISOString() }),
        ...(maxDate && { max_date: maxDate?.toISOString() }),
    });
    // Get all fixtures in date range for competitions
    var fixturesWithCompetition: fixtureWithCompetition[] = [];
    for (var i = 0; i < competitions.length; i++) {
        const competition =  competitions[i]
        const fixtures = await dashboardService.getFixtureAlerts(
            1, 1000, competition.CompetitionId,
            {
                ...(minDate && { min_date: minDate?.toISOString() }),
                ...(maxDate && { max_date: maxDate?.toISOString() }),
            }
        )
        for (var j = 0; j < fixtures.fixtures.length; j++) {
            const fixture = fixtures.fixtures[j]
            fixturesWithCompetition.push({competition, fixture})
        }
    }
    // Sort all fixtures by date
    fixturesWithCompetition.sort((a, b) => {
        const aDate = new Date(a.fixture.date).getTime();
        const bDate = new Date(b.fixture.date).getTime();
        return bDate - aDate;
    })
    // Convert to csv
    const csv = fixturesToCSV(fixturesWithCompetition)
    // Save file
    const blob = new Blob([csv], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${minDate?.toLocaleDateString()}-${maxDate?.toLocaleDateString()}-fixtures.csv`;
    link.click();
}

function fixturesToCSV(fixtures: fixtureWithCompetition[]): string {
    if (fixtures.length === 0) return '';

    // Map the data to CSV rows
    const csvRows = fixtures.map(f => {
        return headers.map((header, i) => {
            var value: any
            switch (header.key) {
                case "competition_name":
                    value = f.competition.CompetitionName;
                    break
                case "date":
                    value = new Date(f.fixture.date).toLocaleDateString();
                    break
                case "time":
                    value = new Date(f.fixture.date).toLocaleTimeString();
                    break
                case "vs":
                    value = "VS"
                    break
                default:
                    const fixture: any = f.fixture
                    value = fixture[header.key];
                }
            // Handle angles
            if (i > 6 && i < 16) {
                const files: File[] = f.fixture.files
                value = files.find((val) => (
                    DisplayAngle.get(val.sub_type)?.toString() === header.value
                ))?.status ?? "N/A"
            }
            return `"${value}"`;
        }).join(',');
    });

    // Combine the header and data rows into a CSV string
    const csvString = [headers.map((val) => (val.value)).join(','), ...csvRows].join('\n');

    return csvString;
};

export {}
